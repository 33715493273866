import $ from 'jquery'

if ( $('.single-event .breadcrumbs').length ) {
  $('#breadcrumbs span span:nth-child(2) a').text('News & Updates').attr('href', '/news-updates')

  let title = $('.breadcrumb_last').text();

  let wordLimit = 3;

  let wordsArray = title.split(' ');

  let textLength = wordsArray.length;

  if (textLength > wordLimit) {

    let truncated = '';
    for (let i = 0; i < wordLimit; i++)
      truncated += wordsArray[i] + " ";

    $('.breadcrumb_last').html(truncated + '<span class="ellipses">...</span>');
  }
}