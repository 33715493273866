import $ from 'jquery'

$(document).ready( initHero )

function initHero(){
  if ($('.hero.is-style-hero-var-2').length ) {
    $('.header').addClass('header--transparent');
  }
  if ($('.hero.is-style-hero-var-1').length) {
    $('.header').addClass('header--transparent-dark');
  }
  if ($('body').hasClass('error404')) {
    $('.header').addClass('header--transparent');
  }
}
