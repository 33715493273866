import $ from 'jquery'
const $doc = $(document)
let currentURL = new URL(decodeURI(window.location.href))

// State initialization
let state = {
    action: '',
    topic: [],
    type: [],
    perPage: 9,
    ajaxPaged: 1,
    postType: 'post'
}

if ($('body').hasClass('page-template-portfolio') ) {
    state = {
        action: '',
        year: [],
        vertical: [],
        perPage: -1,
        ajaxPaged: 1,
        postType: 'portfolio-company'
    }
}

// Function to filter posts
function filterPosts(state, postType, keyword = false, clear = false) {
    const postsList = $('.posts-filters__posts')
    const base = $('[data-baseurlpagination]').length ? $('[data-baseurlpagination]').data('baseurlpagination') : ''
    const tag = $('[data-tagid]').length ? $('[data-tagid]').data('tagid') : ''
    let filters = ['topic', 'type']
    if ($('body').hasClass('page-template-portfolio')) {
        filters = ['year', 'vertical']
    }
    state.postType = postType ? postType : state.postType
    state.action = state.postType == 'post' ? 'ajaxfilter' : 'portfoliofilter'

    // Clear filters if necessary
    if (clear) {
        filters.forEach(filter => {
            if (filter !== 'perPage') {
                state[filter] = []
            }
        })
        for (const key of currentURL.searchParams.keys()) {
            if (key !== 'perPage') currentURL.searchParams.delete(key)
        }
    }

    filters.forEach(filter => {
        if (!currentURL.searchParams.get(filter) && !currentURL.searchParams.get('search') && state[filter].length < 1) {
            state[filter] = []
        }
        if (!currentURL.searchParams.get(filter) && keyword !== false) {
            state[filter] = []
        }
    })

    const { action, perPage, ajaxPaged } = state
    const data = { action, base, perPage, ajaxPaged, tag }

    filters.forEach(filter => {
        if (state[filter].length > 0) {
            data[filter] = filter === 'perPage' ? parseInt(state[filter]) : JSON.stringify(state[filter])
        }
    })
    // AJAX call
    $.ajax({
        url: SITE.ajaxurl,
        type: 'POST',
        data,
        beforeSend: function (xhr) {
            postsList.empty()
        },
        success: function (res) {
            if (res && res.trim()) {  // Ensure the response isn't just whitespace
                postsList.html(res)
            } else {
                console.error("Unexpected response:", res)
                postsList.html('<p>Error: Unable to load posts.</p>')
            }
        },
        error: function (xhr, status, error) {
            console.error("AJAX Error:", error)
            postsList.html('<p>Error: Unable to load posts.</p>')
        }
    })

    // Update the URL search parameters
    filters.forEach(filter => {
        if (state[filter].length >= 1) {
            currentURL.searchParams.set(filter, filter === 'perPage' ? parseInt(state[filter]) : JSON.stringify(state[filter]))
        } else if (currentURL.searchParams.has(filter)) {
            currentURL.searchParams.delete(filter)
        }
    })

    // Check if on page 1 and adjust the URL accordingly
    if (state.ajaxPaged === 1) {
        currentURL.searchParams.delete('show_page')
        if (currentURL.pathname.match('/page')) {
            currentURL.href = currentURL.href.replace(/page\/\d*\//, '')
        }
    }

    window.history.replaceState(state, '', currentURL)
}

// Function to handle checkbox filters
function selectArchiveFilter(state, element) {
    const filter = $(element).data('filter')
    const id = $(element).attr('id')
    const name = $(element).data('name')

    if ($(element).is(':checked')) {
        state[filter].push(id)
        $('.js-selected-filter-area').addClass('is-active')
        $('.posts-filters__selected__filters').addClass('is-active')
        if (!$(`.js-selected-filter [data-id="${id}"]`).length) {
            $('.posts-filters__selected__filters').append(`
                <div class="js-selected-filter"><a class="js-clear-this" data-filter="${filter}" data-id="${id}" href="javascript:void(0)">${name}</a><span>,</span></div>
            `)
        }
    } else {
        state[filter] = state[filter].filter(topic => topic !== id)
        $(`.js-selected-filter [data-id="${id}"]`).parent().remove()
    }

    if (!$('.js-selected-filter').length) {
        $('.posts-filters__selected__filters').removeClass('is-active')
        $('.js-selected-filter-area').removeClass('is-active')
    }

    if ($('body').hasClass('page-template-portfolio')) {
        filterPosts(state, 'portfolio-company')
    } else {
        filterPosts(state, 'post')
    }
}

// Initialization function
function initFilters() {
    // Custom event listener to update the page with the data passed through the most recent query
    document.addEventListener('postsFound', (e) => {
        const { detail } = e
        let pagination

        if (detail.pagination) {
            pagination = detail.pagination
                .replace(/&amp;/g, "&")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#039;/g, "'")
        }

        $('.posts-filters__bottom-details').replaceWith(pagination)

        const $pagination = $('.posts-filters__post-pagination')
        $pagination.find('.page-numbers').each(function() {
            if ($(this).is('a')) {
                const href = $(this).attr('href')
                let newURL = new URL(currentURL)
                const paginationURL = href ? new URL(decodeURI(href)) : ''

                if (paginationURL.searchParams.get('show_page')) {
                    newURL.searchParams.set('show_page', paginationURL.searchParams.get('show_page'))
                }
                if (paginationURL.searchParams.get('paged')) {
                    newURL.searchParams.set('paged', paginationURL.searchParams.get('paged'))
                }
                if (paginationURL.searchParams.get('perPage')) {
                    newURL.searchParams.set('perPage', paginationURL.searchParams.get('perPage'))
                }
                if (paginationURL.hash) {
                    newURL.hash = paginationURL.hash
                }

                $(this).attr('href', newURL.href)
            }
        })
    })

    // Event binding
    $doc.on('change', '.js-checkbox-filter', function () {
        selectArchiveFilter(state, this)
        //$('.js-clear-all').show()
    })

    $doc.on('click', '.js-per-page', function (event) {
        event.preventDefault()
        state.perPage = $(this).text()
        $('.js-per-page').removeClass('is-active')
        $(this).addClass('is-active')
        filterPosts(state, 'post')
    })

    $doc.on('click', '.js-clear-all', function (e) {
        e.preventDefault();
        filterPosts(state, '', false, true)
        $('.js-selected-filter-area').removeClass('is-active')
        $('.js-checkbox-filter').each(function () {
            $(this).prop('checked', false).trigger('change')
        })
        //$('.js-clear-all').hide()
    })

    $doc.on('click', '.js-clear-this', function () {
        const id = $(this).data('id')
        $(`.js-checkbox-filter#${id}`).prop('checked', false).trigger('change')
        $(this).closest('.js-selected-filter').remove()

        if (!$('.js-selected-filter').length) {
            $('.posts-filters__selected__filters').removeClass('is-active')
            $('.js-selected-filter-area').removeClass('is-active')
        }
    })
    
    $doc.on('click', '.js-toggle-filter', function () {
        $('.posts-filters__links').toggleClass('is-active')
        $('.posts-filters__body').toggleClass('is-disabled')
        $('.js-filter-canvas').toggleClass('is-filtering')
        if ( $('.posts-filters__links').hasClass('is-active') ) {
            $('.posts-filters__links .js-toggle-filter').focus();
        } else {
            $('.posts-filters__results .js-toggle-filter').focus();
        }
    })

    $('.js-toggle-filter').on("keypress", function (event) {
        if (event.which === 13) { // 13 is the key code for Enter
            $('.posts-filters__links').toggleClass('is-active')
            $('.posts-filters__body').toggleClass('is-disabled')
            $('.js-filter-canvas').toggleClass('is-filtering')
            if ($('.posts-filters__links').hasClass('is-active')) {
                $('.posts-filters__links .js-toggle-filter').focus();
            } else {
                $('.posts-filters__results .js-toggle-filter').focus();
            }
        }
    });
      
    // Initialize filters on page load
    $(function () {
        let showClearAll = false

        for (const [key, value] of currentURL.searchParams.entries()) {
            const filterValues = JSON.parse(value) || [value]
            
            Array.isArray(filterValues) && filterValues.forEach(val => {
                if ((key.includes('topic') || key.includes('type')) && $(`.js-checkbox-filter`).length) {
                    $(`.js-checkbox-filter#${val}`).prop('checked', true)
                    state[key].push(val)
                    showClearAll = true
                }
                if (key.includes('perPage') && $(`[data-pageview=${val}]`).length) {
                    $(`[data-pageview=${val}]`).addClass('is-active')
                    state[key] = parseInt(val, 10)
                }
            })
        }

        if (showClearAll) {
            // Uncomment to show the clear all button when filters are applied
            // $('.js-clear-all').show()
        }
    })
}

initFilters()