import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

function openModal( target ) {
  $.magnificPopup.open({
    items: {
      src: target,
      type: 'inline',
    },
    callbacks: {
      close: function(){
        const url = new URL( window.location )
        url.hash = ''
        history.pushState( {}, '', url )
      }
    },
    closeBtnInside: true,
    removalDelay: 300,
    mainClass: 'mfp-fade'
  });
}

$doc.ready( ()=>{
  const hash = window.location.hash
  if (hash && $(hash).length && $(hash).hasClass('mfp-hide') ){
    openModal( hash )
  }
  $doc.on('click', '.js-open-modal', e => {openModal( e.target.hash )} );
  $('.js-open-image-modal').magnificPopup({
    type: "image",
    image: {
      markup: '<div class="mfp-figure">' +
        '<div class="mfp-close"></div>' +
        '<div class="mfp-img"></div>' +
        '<div class="mfp-bottom-bar">' +
        '<div class="mfp-title"></div>' +
        '<div class="mfp-counter"></div>' +
        '</div>' +
        '</div>',
      cursor: 'mfp-zoom-out-cur',
      titleSrc: 'title',
      verticalFit: true,
      tError: '<a href="%url%">The image</a> could not be loaded.'
    },
    removalDelay: 300,
    mainClass: 'mfp-fade mfp-image-fade'
  });
})
