/* import { select, subscribe } from '@wordpress/data'

const isEditorReadyPromise = new Promise( ( resolve ) => {
    const unsubscribe = subscribe( () => {
        //approximate editor ready by checking for clean post/blocks loaded
        const isCleanNewPost = select( 'core/editor' ).isCleanNewPost();
        if ( isCleanNewPost ) {
            unsubscribe();
            resolve();
        }
        const blocks = select( 'core/editor' ).getBlocks();
        if ( blocks.length > 0 ) {
            unsubscribe();
            resolve();
        }
    } );
} );

isEditorReadyPromise.then( () => run() )

const run =() => {
    const { getBlocks } = select('core/block-editor')
    const { getEditedPostAttribute } = select('core/editor')

    console.info(getBlocks())
    console.info(getEditedPostAttribute('template'))
}
  
  
 */