import $ from 'jquery'

if ( $('.single-post .breadcrumbs').length ) {
  $('#breadcrumbs span span:first-child').after(' / <span><a href="/news-updates">News & Updates</a></span>');

  let title = $('.breadcrumb_last').text();

  let wordLimit = 3;

  let wordsArray = title.split(' ');

  let textLength = wordsArray.length;

  if (textLength > wordLimit ) {

    let truncated = '';
    for (let i = 0; i < wordLimit; i++)
      truncated += wordsArray[i] + " "; 

    $('.breadcrumb_last').html(truncated + '<span class="ellipses">...</span>');
  }

}